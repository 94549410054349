.blogPage {
  width: 100%;
  background: #fff;
}

.mainContent {
  padding-top: 56px; /* Height of nav */
}

.heroSection {
  padding: 10px 24px;

  width: auto;
  height: 351px;
  
  background-image: url('../../images/Texture.png');
  background-size: cover;
  background-position: center;
  background-color: #11311B; /* Fallback color */
}

.heroSection h1 {
  font-size: 62px;
  color: #C7AE48;
  margin-bottom: 14px;
  text-align: left;
  font-family: "Roboto";
  padding-left: 143px;
  font-weight: 400;
  height: 74px;
}

.heroSection p {
  font-size: 19px;
  color: #FFEFCB;
  max-width: 619px;
  height: 30px;
  line-height: 1.6;
  padding-left: 143px;
  font-weight: 300;
}

.articlesGrid {
  max-width: 1366px;
  margin: 0 auto;
  padding: 48px 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 40px;
  align-items: start; /* Prevent grid items from stretching */
  grid-auto-rows: auto; /* Allow each row to size based on content */
}

.articleCard {
  background: #fff;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, 0.03);
  cursor: pointer;
  position: relative;
  height: auto;
  backface-visibility: hidden; /* Ensures smooth transitions */
}

.articleCard:hover {
  transform: translateY(-6px);
  box-shadow: 0 12px 30px rgba(17, 49, 27, 0.06), 0 4px 8px rgba(17, 49, 27, 0.03);
}

.imageWrapper {
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
}

.articleImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.05));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.0s ease;
}

.articleCard:hover .imageOverlay {
  opacity: 1;
}

.articleCard:hover .articleImage {
  transform: scale(1.04);
}

.readMoreOverlay {
  color: white;
  font-weight: 500;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.0s ease;
  transform: translateY(6px);
  opacity: 0;
}

.articleCard:hover .readMoreOverlay {
  transform: translateY(0);
  opacity: 1;
}

.cardContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(252, 252, 252, 0.4));
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.category {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.65rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: rgba(17, 49, 27, 0.04);
  color: #11311B;
  margin: 0 auto;
  letter-spacing: 0.7px;
  box-shadow: 0 1px 2px rgba(17, 49, 27, 0.02);
  transition: all 0.2s ease;
  min-height: 22px;
  cursor: default;
  border: 1px solid rgba(17, 49, 27, 0.08);
}

.category:hover {
  background-color: rgba(17, 49, 27, 0.06);
  transform: translateY(-1px);
}

.date {
  color: rgba(17, 49, 27, 0.7);
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400;
}

.articleTitle {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.35;
  min-height: 0;
  display: block;
  overflow: visible;
  font-weight: 600;
  color: #11311B;
}

.articleTitle a {
  color: #11311B;
  text-decoration: none;
  transition: color 0.2s ease;
}

.description {
  color: rgba(17, 49, 27, 0.8);
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: color 0.2s ease;
  flex-grow: 1;
}

.articleMeta {
  border-top: 1px solid rgba(17, 49, 27, 0.06);
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.authorInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.authorImage {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  z-index: 1;
}

.articleCard:hover .authorImage {
  transform: scale(1.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
  z-index: 2;
}

.authorDetails {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.author {
  font-weight: 600;
  color: #11311B;
  font-size: 14px;
  line-height: 1.3;
}

.readTime {
  color: rgba(17, 49, 27, 0.7);
  font-size: 12px;
  letter-spacing: 0.2px;
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .articlesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 32px 24px;
  }
}

/* Mobile (393px) */
@media (max-width: 800px) {
  .heroSection {
    text-align: center;
    height: 230px;
    padding-top: 20px;
  }

  .heroSection h1 {
    font-size: 32px;
    padding-left: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .heroSection p {
    font-size: 16px;
    padding-left: 0;
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .articlesGrid {
    grid-template-columns: 1fr;
    padding: 24px 16px;
    gap: 24px;
  }

  .cardContent {
    padding: 20px 16px;
  }

  .imageWrapper {
    height: 180px;
  }
  
  .description {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 14px;
  }
  
  .categoryWrapper {
    margin-bottom: 10px;
  }
  
  .articleTitle {
    font-size: 18px;
  }
  
  .authorImage {
    width: 34px;
    height: 34px;
    /* Hover effect will inherit from the main rule */
  }
}

/* Remove text decoration from all links inside the card */
.articleCard a {
  text-decoration: none;
  pointer-events: none;
}

/* Add focus outline for accessibility */
.articleCard:focus {
  outline: 2px solid #11311B;
  outline-offset: 2px;
}

/* Optional: Hide focus outline when using mouse */
.articleCard:focus:not(:focus-visible) {
  outline: none;
}

/* Modify the hover effect to only change description color, not title */
.articleCard:hover .articleTitle a {
  color: #11311B; /* Keep title black on hover */
}

.articleCard:hover .description {
  color: rgba(17, 49, 27, 0.9);
}

/* Fix hover states */
.articleTitle a:hover {
  color: #11311B; /* Override any other hover effects to keep the title black */
} 