.testimonialSection {
  background: url('../../images/Texture.png');
  background-size: cover;
  background-position: center;
  padding: 30px 0;
  color: #fff;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Subtle animated background pattern */
.testimonialSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0.4;
  z-index: 1;
}

@keyframes subtleMove {
  0% { background-position: 0 0; }
  100% { background-position: 60px 60px; }
}

.container {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.content {
  max-width: 900px;
  margin: 0 auto;
}

.testimonialWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.testimonialWrapper:hover {
  transform: translateY(-5px);
}

.imageWrapper {
  flex-shrink: 0;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.imageWrapper:hover {
  transform: scale(1.05);
}

.imageCircle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background: transparent;
}

.testimonialContent {
  flex: 1;
  text-align: center;
  max-width: 600px;
  position: relative;
  padding: 20px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.stars {
  color: #D4AF37;
  font-size: 24px;
  margin-bottom: 16px;
  letter-spacing: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 4px;
  animation: starFloat 3s ease-in-out infinite;
}

@keyframes starFloat {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.stars span {
  opacity: 0;
  animation: starFade 0.5s ease forwards;
}

.stars span:nth-child(1) { animation-delay: 0.1s; }
.stars span:nth-child(2) { animation-delay: 0.2s; }
.stars span:nth-child(3) { animation-delay: 0.3s; }
.stars span:nth-child(4) { animation-delay: 0.4s; }
.stars span:nth-child(5) { animation-delay: 0.5s; }

@keyframes starFade {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.quote {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 24px;
  font-style: italic;
  color: #fff;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 0 20px;
}

.authorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(199, 174, 72, 0.2);
}

.divider {
  color: #C7AE48;
  font-weight: 300;
  opacity: 0.8;
}

.author {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.05em;
  position: relative;
  padding-bottom: 4px;
}

.author::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #C7AE48;
  transition: width 0.3s ease;
}

.author:hover::after {
  width: 100%;
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .testimonialSection {
    padding: 60px 0;
  }

  .content {
    width: 100%;
  }

  .testimonialWrapper {
    gap: 40px;
  }

  .imageCircle {
    width: 140px;
    height: 140px;
  }

  .quote {
    font-size: 20px;
  }
}

/* Mobile (393px) */
@media (max-width: 393px) {
  .testimonialSection {
    padding: 40px 0;
  }

  .testimonialWrapper {
    flex-direction: column;
    gap: 24px;
    text-align: center;
  }

  .testimonialContent {
    text-align: center;
  }

  .imageCircle {
    width: 120px;
    height: 120px;
  }

  .quote {
    font-size: 18px;
  }

  .author {
    font-size: 14px;
  }
}

@media (max-width: 730px) {
  .testimonialSection {
    padding: 40px 24px;
    height: auto;
  }

  .content {
    width: 100%;
  }

  .testimonialWrapper {
    flex-direction: column;
    gap: 32px;
  }

  .imageWrapper {
    width: 175px;
    height: 175px;
    margin: 0 auto;
  }

  .testimonialContent {
    padding: 24px;
  }

  .stars {
    font-size: 20px;
    margin: 0;
    letter-spacing: 3px;
  }

  .quote {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    padding: 16px 0;
  }

  .authorWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
  }

  .divider {
    font-size: 14px;
  }

  .author {
    font-size: 14px;
    margin: 0;
  }
} 