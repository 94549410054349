.gridSection {
  padding: 35px;
  background: #fff;
  position: relative;
}

.gridSection::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 80%;
  left: 10%;
  bottom: 0;
  background: linear-gradient(90deg, rgba(17, 49, 27, 0), rgba(17, 49, 27, 0.1), rgba(17, 49, 27, 0));
}

.container {
  max-width: 1430px;
  
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 1;
}

.imageWrapper {
  width: 715px;
  height: 715px;
  overflow: hidden;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.imageWrapper:hover .image {
  transform: scale(1.03);
}

.imageWrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: opacity 0.4s ease;
}

.imageWrapper:hover::after {
  opacity: 1;
}

.textWrapper {
  width: 715px;
  height: 715px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #ffffff 0%, #f8f8f8 100%);
  position: relative;
  z-index: 1;
}

.textContent {
  text-align: center;
  padding: 40px;
  max-width: 80%;
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.textWrapper:hover .textContent {
  transform: translateY(-5px);
}

.textContent h3 {
  font-size: 24px;
  color: #11311B;
  margin-bottom: 16px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
  padding-bottom: 12px;
}

.textContent h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: #C7AE48;
  transition: width 0.3s ease;
}

.textContent:hover h3::after {
  width: 70px;
}

.textContent p {
  font-size: 16px;
  line-height: 1.6;
  color: #4A4A4A;
  font-weight: 400;
  letter-spacing: 0.02em;
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .imageWrapper,
  .textWrapper {
    width: 500px;
    height: 500px;
  }
}


@media (max-width: 430px) {
  .gridSection {
    padding: 0;
  }

  .container {
    flex-direction: column;
    max-width: 100%;
    margin: 0;
  }

  /* First Image-Text Pair */
  .imageWrapper {
    width: 100%;
    height: 400px;
    order: 1;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .textWrapper {
    width: 100%;
    height: auto;
    order: 2;
    padding: 32px 24px;
    background-color: white;
  }

  .textContent {
    padding: 0;
    text-align: center;
  }

  .textContent h3 {
    font-size: 24px;
    color: #11311B;
    margin-bottom: 16px;
  }

  .textContent p {
    font-size: 16px;
    line-height: 1.4;
    color: #11311B;
  }

  /* Second Image-Text Pair */
  .imageWrapper:nth-child(3) {
    order: 3;
  }

  .textWrapper:nth-child(4) {
    order: 4;
  }
}