html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
  touch-action: pan-y pinch-zoom;
  overflow-y: auto;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes slideOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes progress-bar-stripes {
  from { background-position: 40px 0; }
  to { background-position: 0 0; }
}

/* Level 1: Basic scroll lock */
.is-scrollLocked {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

/* iOS Scroll Management */
.is-momentumScrollable {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  position: relative;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
}

/* iOS Safari bottom area fix */
.MuiContainer-root {
  padding-bottom: calc(env(safe-area-inset-bottom) + 44px);
  min-height: calc(100vh - env(safe-area-inset-bottom) - 44px);
}

/* Public pages scroll behavior */
body.public-page {
  position: static;
  overflow-y: auto;
  height: auto;
}

/* Protected routes scroll behavior - remains unchanged */
body:not(.public-page) {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Allow scroll only in content area */
.MuiContainer-root, 
.MuiDialog-paper,
.MuiDrawer-paper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  /* Hide scrollbar completely */
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none !important;
  }
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  /* Prevent touch on scrollbar area */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }
}

/* Additional touch prevention for iOS */
@supports (-webkit-touch-callout: none) {
  .MuiContainer-root {
    touch-action: pan-y pinch-zoom;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
  }
}
