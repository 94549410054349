.contentSection {
  width: 100%;
  padding: 30px 0;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1430px;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
}

.imageWrapper {
  width: 715px;
  height: 715px;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.imageWrapper:hover .image {
  transform: scale(1.03);
}

/* Subtle overlay on hover */
.imageWrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: opacity 0.4s ease;
}

.imageWrapper:hover::after {
  opacity: 1;
}

.textWrapper {
  width: 715px;
  height: 715px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #ffffff 0%, #f8f8f8 100%);
  position: relative;
  z-index: 1;
}

.textContent {
  text-align: center;
  padding: 40px;
  max-width: 80%;
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.textWrapper:hover .textContent {
  transform: translateY(-5px);
}

.title {
  font-size: 24px;
  color: #11311B;
  margin-bottom: 24px;
  line-height: 1.6;
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
}

.title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background: #C7AE48;
  transition: width 0.3s ease;
}

.textContent:hover .title::after {
  width: 80px;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  color: #4A4A4A;
  font-weight: 400;
  letter-spacing: 0.02em;
}

/* Tablet (1024px) */


/* Mobile (430px) */
@media (max-width: 430px) {
  .contentSection {
    padding: 20px 0;
    background: white;
    margin-bottom: 0px;
  }

  .container {
    flex-direction: column;
    max-width: 100%;
    margin: 0;
    padding: 0;
    background: white;
  }

  /* Image Section */
  .imageWrapper {
    width: 100%;
    height: 100%;
    order: 1;
    background: white;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Text Section */
  .textWrapper {
    width: 100%;
    height: auto;
    order: 2;
    padding: 32px 24px;
    background: white;
  }

  .textContent {
    padding: 0;
    text-align: center;
    background: white;
  }

  .title {
    font-size: 24px;
    color: #11311B;
    margin-bottom: 16px;
    line-height: 1.4;
    text-align: center;
  }

  .description {
    font-size: 16px;
    line-height: 1.4;
    color: #11311B;
    margin-bottom: 0;
    text-align: center;
  }

} 