.blogDetailPage {
  width: 100%;
  background: #fff;
}

.mainContent {
  padding-top: 56px; /* Height of nav */
  max-width: 1366px;
  margin: 0 auto;
}

.loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #11311B;
}

.breadcrumbs {
  padding: 24px;
  color: #4A4A4A;
  font-size: 14px;
}

.breadcrumbs a {
  color: #11311B;
  text-decoration: none;
}

.separator {
  margin: 0 8px;
  color: #4A4A4A;
}

.heroSection {
  width: 100%;
  margin-bottom: 10px;
}

.heroImage {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.featuredImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.articleContent {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
}

.articleHeader {
  margin-bottom: 8px;
  text-align: center;
}

.category {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  background: rgba(17, 49, 27, 0.1);
  color: #11311B;
  font-size: 14px;
  margin-bottom: 16px;
}

.articleHeader h1 {
  font-size: 48px;
  color: #11311B;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.2;
}

.authorSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.authorPhoto {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
}

.authorInfo {
  text-align: left;
}

.authorName {
  display: block;
  font-weight: 600;
  color: #11311B;
  margin-bottom: 4px;
}

.articleMeta {
  color: #4A4A4A;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dot {
  width: 4px;
  height: 4px;
  background: #4A4A4A;
  border-radius: 50%;
}

.articleBody {
  font-size: 18px;
  line-height: 1.8;
  color: #2C2C2C;
  
  /* Styling for article content */
  h2, h3, h4 {
    color: #11311B;
    margin: 32px 0 16px;
  }

  p {
    margin-bottom: 24px;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 32px 0;
    border-radius: 8px;
  }

  blockquote {
    border-left: 4px solid #11311B;
    margin: 32px 0;
    padding: 16px 32px;
    background: rgba(17, 49, 27, 0.05);
    font-style: italic;
  }

  pre {
    background: #f8f9fa;
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
    margin: 24px 0;
  }

  code {
    font-family: monospace;
    background: #f8f9fa;
    padding: 2px 6px;
    border-radius: 4px;
  }
}

.shareSection {
  margin-top: 64px;
  padding-top: 32px;
  border-top: 1px solid #E5E5E5;
  text-align: center;
}

.shareSection h3 {
  color: #11311B;
  margin-bottom: 16px;
}

.shareButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.shareButtons button {
  padding: 8px 24px;
  border: 1px solid #11311B;
  background: none;
  color: #11311B;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.shareButtons button:hover {
  background: #11311B;
  color: #fff;
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .heroImage {
    height: 400px;
  }

  .articleHeader h1 {
    font-size: 36px;
  }

  .articleBody {
    font-size: 16px;
  }
}

/* Mobile (393px) */
@media (max-width: 393px) {
  .heroImage {
    height: 300px;
  }

  .articleHeader h1 {
    font-size: 28px;
  }

  .articleContent {
    padding: 0 16px;
  }

  .shareButtons {
    flex-direction: column;
  }

  .shareButtons button {
    width: 100%;
  }
} 