.homePage {
  width: 100%;
  overflow-x: hidden;
}

.container {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
}

/* Hero Section */
.heroSection {
  background: #fff;
  min-height: 100vh;
  position: relative;
}

.nav {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoWrapper {
  width: 48px;
  height: 48px;
  background: #0A3622;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.signInBtn {
  padding: 8px 24px;
  background: #0A3622;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.heroContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  padding: 80px 0;
  align-items: center;
}

.heroLeft {
  max-width: 500px;
}

.heroTitle {
  font-size: 64px;
  line-height: 1.1;
  margin-bottom: 24px;
  color: #0A1929;
}

.heroText {
  font-size: 18px;
  line-height: 1.6;
  color: #4A4A4A;
  margin-bottom: 32px;
}

.learnMoreBtn {
  padding: 12px 32px;
  background: #0A3622;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.heroImagePlaceholder {
  width: 100%;
  height: 500px;
  background: #F5F5F5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4A4A4A;
}

/* Scrolling Banner */
.scrollingBanner {
  padding: 16px 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  overflow: hidden;
}

.bannerContent {
  display: flex;
  gap: 32px;
  color: #4A4A4A;
  animation: scroll 20s linear infinite;
}

.dot {
  opacity: 0.5;
}

/* Features Section */
.featuresSection {
  padding: 80px 0;
  text-align: center;
}

.featuresTitle {
  font-size: 32px;
  margin-bottom: 64px;
  color: #0A1929;
}

.featureSteps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 48px;
}

.step {
  text-align: center;
}

.stepIcon {
  width: 80px;
  height: 80px;
  background: #0A3622;
  border-radius: 50%;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.stepArrow {
  width: 100px;
  height: 2px;
  background: #0A3622;
  position: relative;
}

.stepArrow::after {
  content: '';
  position: absolute;
  right: 0;
  top: -4px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #0A3622;
  border-right: 2px solid #0A3622;
  transform: rotate(45deg);
}

/* Content Blocks */
.contentBlock {
  padding: 80px 0;
  background: #F5F5F5;
}

.contentGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  align-items: center;
}

.imagePlaceholder {
  width: 100%;
  height: 400px;
  background: #E0E0E0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4A4A4A;
}

/* Testimonial Section */
.testimonialSection {
  background: #0A3622;
  padding: 64px 0;
  color: white;
  text-align: center;
}

.avatarWrapper {
  width: 80px;
  height: 80px;
  margin: 0 auto 24px;
}

.avatarPlaceholder {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
}

.stars {
  color: #D4AF37;
  font-size: 24px;
  margin-bottom: 24px;
}

.quote {
  font-size: 20px;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 16px;
}

/* Newsletter Section */
.newsletterSection {
  background: #0A3622;
  padding: 80px 0;
  color: white;
  text-align: center;
}

.newsletterContent {
  max-width: 600px;
  margin: 0 auto;
}

.subscribeForm {
  display: flex;
  gap: 16px;
  margin: 32px 0;
}

.emailInput {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 4px;
}

.subscribeBtn {
  padding: 12px 32px;
  background: #D4AF37;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.socialLinks {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 48px;
}

.socialLink {
  color: white;
  text-decoration: none;
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

/* Responsive */
@media (max-width: 1024px) {
  .heroContent {
    gap: 32px;
  }
  
  .featureSteps {
    flex-direction: column;
  }
  
  .stepArrow {
    transform: rotate(90deg);
  }
}

@media (max-width: 393px) {
  .container {
    padding: 0 16px;
  }

  .heroContent {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 40px 0;
  }

  .heroLeft {
    max-width: 100%;
  }

  .heroTitle {
    font-size: 36px;
  }

  .contentGrid {
    grid-template-columns: 1fr;
  }
}

.mainContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

/* All direct section children span the content area by default */
.mainContent > section {
  grid-column: content-start / content-end;
  display: grid;
  grid-template-columns: inherit;
}

/* Full-width sections when needed */
.mainContent > section.fullWidth {
  grid-column: full-start / full-end;
}

/* CSS Custom Properties for consistent spacing */
:root {
  --section-spacing: 80px;
  --content-max-width: 1366px;
  --horizontal-padding: 24px;
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    --section-spacing: 60px;
    --horizontal-padding: 40px;
  }
  
  @media (max-width: 393px) {
    --section-spacing: 40px;
    --horizontal-padding: 20px;
  }
}

.footer {
  width: 100%;
  padding: 16px 32px;
  position: relative;
  background-color: #0A3622;
  z-index: 2;
}

.footerContent {
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerLinks {
  display: flex;
  gap: 24px;
}

.footerLink {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.footerLink:hover {
  opacity: 1;
  text-decoration: none;
}

.footerRight {
  display: flex;
  align-items: center;
  gap: 24px;
}

.socialIcons {
  display: flex;
  gap: 24px;
}

.socialIcon {
  color: #fff;
  opacity: 0.8;
  transition: opacity 0.2s;
  font-size: 20px;
}

.socialIcon:hover {
  opacity: 1;
  color: #fff;
}

.copyright {
  color: white;
  opacity: 0.8;
  font-size: 14px;
  white-space: nowrap;
  margin-left: 24px;
}

@media (max-width: 1024px) {
  .footerContent {
    flex-direction: column;
    gap: 16px;
  }
  
  .footerRight {
    flex-direction: column;
    gap: 16px;
  }
  
  .copyright {
    margin-left: 0;
  }
}

@media (max-width: 393px) {
  .footer {
    padding: 16px;
  }
  
  .footerLinks {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  .socialIcons {
    gap: 16px;
  }
}