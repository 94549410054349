.processSection {
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 20px 0;
  position: relative;

  overflow: hidden;
  z-index: 1;
}

.processSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 20% 30%, rgba(199, 174, 72, 0.05) 0%, rgba(255, 255, 255, 0) 50%);
  z-index: -1;
}

.container {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 24px;
}

.title {
  font-size: 32px;
  color: #11311B;
  margin-bottom: 48px;
  text-align: center;
  font-weight: 600;
  position: relative;
  padding-bottom: 16px;
}

.title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(90deg, rgba(199, 174, 72, 0.3), rgba(199, 174, 72, 1), rgba(199, 174, 72, 0.3));
}

.stepsContainer {
  width: 100%;
  max-width: 987px;
  margin: 0 auto;
  position: relative;
  transition: transform 0.5s ease;
}

.stepsContainer svg {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.05));
  transition: all 0.5s ease;
}

.stepsContainer:hover svg {
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.1));
}

.stepsText {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
}

.stepItem {
  flex: 0 1 auto;
  text-align: center;
  width: 200px;
}

.stepItem:nth-child(1) {
  margin-right: auto;
  margin-left: 70px;
}

.stepItem:nth-child(2) {
  margin: 0 auto;
}

.stepItem:nth-child(3) {
  margin-left: auto;
  margin-right: 70px;
}

.stepTitle, 
.stepDescription {
  font-size: 19px;
  color: #11311B;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  line-height: 1.4;
}

.getStartedButton {
  margin: 60px auto 0;
  display: block;
  width: auto;
  min-width: 220px;
  font-weight: 600;
  height: 50px;
  padding: 12px 32px;
  background-color: #11311B;
  color: #C7AE48;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  z-index: 10;
  text-align: center;
  white-space: nowrap;
  /* 3D floating effect enhancements */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid rgba(199, 174, 72, 0.15);
  transform: translateZ(0);
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
}

/* Hover state enhancement */
.getStartedButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, 0.15);
  background-color: #18422A;
}

/* Add subtle highlight glow */
.getStartedButton::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20px;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(35deg);
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events: none;
}

/* Move the highlight on hover */
.getStartedButton:hover::after {
  left: 120%;
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .getStartedButton {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
  }
}

.button {
  background-color: #11311B;
  color: #C7AE48;
  /* ... rest of button styles ... */
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .stepsContainer {
    max-width: 90%;
  }
  
  .stepTitle, 
  .stepDescription {
    font-size: 16px;
  }
}

/* Mobile (393px) */
@media (max-width: 393px) {
  .stepsText {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .stepItem,
  .stepItem:nth-child(1),
  .stepItem:nth-child(2),
  .stepItem:nth-child(3) {
    margin: 0;
  }

  .stepTitle, 
  .stepDescription {
    font-size: 14px;
  }

  .getStartedButton {
    padding: 12px 24px;
    font-size: 14px;
    width: auto;
    min-width: 200px; /* Updated from 120px to ensure text fits */
    height: auto;
    min-height: 44px;
    touch-action: manipulation;
    white-space: nowrap; /* Prevent text wrapping */
  }
}

/* First, set the default desktop state */
.desktopSteps {
  display: block;
}

.mobileProcess {
  display: none;
}

.mobileProcess img {
  filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.1));
  transition: all 0.5s ease;
}

.mobileProcess:hover img {
  filter: drop-shadow(0 12px 24px rgba(0, 0, 0, 0.15));
}

/* Mobile styles */
@media (max-width: 430px) {
  .processSection {
    padding: 0 0;
  }

  .container {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
    color: #11311B;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
  }

  /* Switch visibility for mobile */
  .desktopSteps {
    display: none !important;
  }

  .mobileProcess {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0;
  }

  .processMobileImage {
    width: 100%;
    max-width: 300px; /* Adjust this value based on your needs */
    height: auto;
    margin: 0 auto;
  }

  .getStartedButton {
    margin-top: 44px;
    margin-bottom: 0px;
    padding: 12px 24px;
    font-size: 14px;
    width: auto;
    min-width: 200px; /* Ensure enough space for text on mobile */
    white-space: nowrap; /* Maintain no-wrap on mobile */
  }
}