.contentSection {
  padding: 40px 0;
  background: #fff;
  position: relative;
  overflow: hidden;
}

/* Subtle background pattern */
.contentSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 0;
}

.container {
  max-width: 1430px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.container:last-child {
  margin-bottom: 0;
}

.container.reversed {
  flex-direction: row-reverse;
}

.imageWrapper {
  width: 715px;
  height: 715px;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.imageWrapper:hover .image {
  transform: scale(1.03);
}

/* Subtle overlay on hover */
.imageWrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.imageWrapper:hover::after {
  opacity: 1;
}

.textWrapper {
  width: 715px;
  height: 715px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #ffffff 0%, #f8f8f8 100%);
  position: relative;
}

.textContent {
  text-align: left;
  padding: 60px;
  max-width: 90%;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.textWrapper:hover .textContent {
  transform: translateY(-5px);
}

.textContent h3 {
  font-size: 24px;
  color: #11311B;
  margin-bottom: 24px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
}

.textContent h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 2px;
  background: #C7AE48;
  transition: width 0.3s ease;
}

.textContent:hover h3::after {
  width: 80px;
}

.textContent p {
  font-size: 16px;
  line-height: 1.6;
  color: #4A4A4A;
  margin-bottom: 24px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.textContent p:last-child {
  margin-bottom: 0;
}

/* Mobile (900px) */
@media (max-width: 900px) {
  .contentSection {
    padding: 20px 0;
  }

  .container {
    flex-direction: column;
    margin-bottom: 0;
    box-shadow: none;
  }

  .container.reversed {
    flex-direction: column;
  }

  .imageWrapper {
    width: 100%;
    height: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .textWrapper {
    width: 100%;
    height: auto;
    padding: 32px 24px;
    background: #fff;
  }

  .textContent {
    padding: 20px;
    margin-bottom: 0;
    max-width: 100%;
  }

  .textContent h3 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .textContent h3::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .textContent p {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
  }
}

/* Additional mobile refinements */
@media (max-width: 480px) {
  .contentSection {
    padding: 0px 0;
  }

  .container {
    margin-bottom: 0;
  }

  .imageWrapper {
    height: 300px;
  }

  .textContent h3 {
    font-size: 24px;
  }

  .textContent p {
    font-size: 16px;
    line-height: 1.6;
  }
} 