.newsletterSection {
  background-color: #11311B;
  padding: 10px 24px;
  text-align: center;
}

.newsletterTitle {
  color: white;
  font-size: 15px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 24px;
}

.avatarWrapper {
  width: 80px;
  height: 80px;
  margin: 0 auto 24px;
}

.avatarPlaceholder {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
}

.name {
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 32px;
  opacity: 0.9;
}

.subscribeForm {
  display: flex;
  gap: 16px;
  max-width: 500px;
  margin: 0 auto 16px;
}

.emailInput {
  flex: 1;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  background: #FFFFFF;
}

.submitButton {
  padding: 12px 32px;
  background: #C7AE48;
  color: #11311B;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submitButton:hover {
  background: #D4BE6A;
}

.privacyText {
  color: white;
  font-size: 12px;
  opacity: 0.8;
}

.privacyLink {
  color: #C7AE48;
  text-decoration: underline;
  font-weight: 500;
}

.privacyLink:hover {
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .subscribeForm {
    flex-direction: column;
    padding: 0 20px;
  }

  .submitButton {
    width: 100%;
  }

  .newsletterTitle {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .newsletterSection {
    padding: 60px 0;
  }
}

@media (max-width: 430px) {
  .newsletterSection {
    padding: 40px 0;
    margin-bottom: 0px;
  }

  .subscribeForm {
    flex-direction: column;
  }

  .submitButton {
    padding: 12px 24px;
    font-size: 14px;
    width: 100%;
    min-height: 44px;
    touch-action: manipulation;
  }
}

@media (max-width: 480px) {
  .newsletterTitle {
    font-size: 11px;
  }
} 