.founderSection {
  padding: 40px 0;
  height: auto;
  background: url('../../images/Texture.png');
  background-size: cover;
  background-position: center;
  text-align: center;

  position: relative;
  z-index: 1;
}

/* Add overlay for the background */
.founderSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  z-index: -1;
}

.founderContent {
  max-width: 700px;
  margin: 0 auto;

  padding: 30px 20px;
  position: relative;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.founderImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 25px;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.3),
    0 0 0 3px rgba(199, 174, 72, 0.3);
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1), box-shadow 0.5s ease;

}

/* Add hover effect for the founder image */
.founderImage:hover {
  transform: scale(1.08);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.4),
    0 0 0 4px rgba(199, 174, 72, 0.5);
}

.founderName {
  font-size: 24px;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
}

.founderName::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 2px;
  background: #C7AE48;
  transition: width 0.3s ease;
}

.founderContent:hover .founderName::after {
  width: 60px;
}

.founderTitle {
  font-size: 14px;
  color: #C7AE48;
  letter-spacing: 1px;
  margin-bottom: 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.founderDescription {
  font-size: 16px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  letter-spacing: 0.02em;
  text-align: center;
}

/* Decorative quotes */
.founderContent::before,
.founderContent::after {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .founderSection {
    padding: 60px 20px;
  }
  
  .founderContent {
    padding: 25px 15px;
  }
}

@media (max-width: 480px) {
  .founderImage {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }
  
  .founderName {
    font-size: 22px;
  }
  
  .founderTitle {
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .founderDescription {
    font-size: 15px;
    max-width: 95%;
  }
} 