/* Import Playfair Display font for premium feel */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');

/* Basic Card Layout with improved styling */
.night-card {
    width: 100%;
    height: 100%;
    background: url('../publicPages/images/Texture.png') #11311B;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 24px;
    position: relative !important;
    /* Special overflow handling - only the card content should be clipped, not absolutely positioned children */
    overflow: visible !important;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-family: sans-serif;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.08);
}

/* Special styling for featured (annual plan) card */
.night-card.featured-card {
    box-shadow: 0 20px 50px rgba(204, 171, 98, 0.25);
    border: 1px solid rgba(204, 171, 98, 0.2);
    transform: scale(1.05);
    z-index: 10;
    overflow: visible !important;
}

.night-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
    transition: all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.night-card.featured-card:hover {
    transform: translateY(-15px) scale(1.05);
    box-shadow: 0 30px 60px rgba(204, 171, 98, 0.3);
}

.night-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    border-radius: 24px;
    overflow: hidden;
}

/* Improved moons with glowing effect */
.moons {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 2;
}

.moons::before {
    content: '';
    position: absolute;
    top: -60px;
    right: -60px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(204, 171, 98, 0.3) 0%, rgba(204, 171, 98, 0) 70%);
    filter: blur(20px);
    opacity: 0.5;
}

.moons::after {
    content: '';
    position: absolute;
    bottom: -40px;
    left: -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(204, 171, 98, 0.2) 0%, rgba(204, 171, 98, 0) 70%);
    filter: blur(15px);
    opacity: 0.5;
}

.first-moon {
    position: absolute;
    top: -120px;
    right: -120px;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(17, 49, 27, 0.8) 0%, rgba(17, 49, 27, 0) 70%);
    filter: blur(30px);
    opacity: 0.6;
}

.second-moon {
    position: absolute;
    bottom: -100px;
    left: -100px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(17, 49, 27, 0.6) 0%, rgba(17, 49, 27, 0) 70%);
    filter: blur(25px);
    opacity: 0.6;
}

.glow-effect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(204, 171, 98, 0.05) 0%, rgba(204, 171, 98, 0) 70%);
    filter: blur(40px);
    opacity: 0.8;
}

.featured-card .glow-effect {
    background: radial-gradient(circle, rgba(204, 171, 98, 0.1) 0%, rgba(204, 171, 98, 0) 70%);
    width: 350px;
    height: 350px;
    animation: pulse 4s ease-in-out infinite alternate;
}

/* Enhanced stars */
.moons .star {
    position: absolute;
    background-color: #fff;
    border-radius: 999px;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
    animation: twinkle ease-in-out infinite alternate;
}

/* Replace the best value badge with the StarBurst SVG */
.star-burst-badge {
    position: absolute !important;
    top: -30px !important;
    right: 10px !important;
    width: 110px !important;
    height: 110px !important;
    z-index: 9999 !important;
    pointer-events: none;
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    overflow: visible !important;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.15));
}

/* Style the SVG itself */
.star-burst-svg {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 9999 !important;
    /* Add refined subtle animation */
    animation: badgePolish 6s infinite ease-in-out;
    transform-origin: center center;
}

/* Text inside the star burst */
.badge-text {
    color: #11311B;
    font-weight: 700;
    font-size: 11px;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 0.5px;
    margin-top: -4px;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.3);
    z-index: 1;
}

/* Make sure the badge stays in position relative to the card */
.night-card:hover .star-burst-badge {
    transform: none !important;
}

/* Define a more polished, subtle animation for the badge */
@keyframes badgePolish {
    0% {
        filter: saturate(1) contrast(1);
        opacity: 1;
    }
    30% {
        filter: saturate(1.08) contrast(1.05);
        opacity: 0.97;
    }
    50% {
        filter: saturate(1.1) contrast(1.08);
        opacity: 1;
    }
    70% {
        filter: saturate(1.08) contrast(1.05);
        opacity: 0.97;
    }
    100% {
        filter: saturate(1) contrast(1);
        opacity: 1;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .star-burst-badge {
        top: -25px !important;
        right: 8px !important;
        width: 90px !important;
        height: 90px !important;
    }
}

@media (max-width: 480px) {
    .star-burst-badge {
        top: -20px !important;
        right: 5px !important;
        width: 80px !important;
        height: 80px !important;
    }
}

/* Plan title styling */
.plan-title {
    display: inline-block;
    position: relative;
}

.featured-title {
    font-size: 1.5rem !important;
    letter-spacing: 0.01em;
}

/* Feature styling */
.feature-item {
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateX(3px);
}

.feature-dot {
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.feature-item:hover .feature-dot {
    transform: scale(1.4);
    background-color: #CCAB62 !important; /* Golden highlight for all cards */
}

/* Button styling */
.subscribe-button {
    position: relative;
    overflow: visible !important;
    transition: transform 0.4s cubic-bezier(0.2, 0.8, 0.2, 1), 
                background-color 0.3s ease, 
                box-shadow 0.3s ease !important;
}

.featured-button {
    font-weight: 700 !important;
    letter-spacing: 0.02em;
}

.button-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    background-size: 200% 100%;
    border-radius: 12px; /* Match button border radius */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.subscribe-button:hover {
    transform: translateY(-3px) !important;
    box-shadow: 0 8px 20px rgba(17, 49, 27, 0.25) !important;
}

.featured-button:hover {
    box-shadow: 0 8px 25px rgba(204, 171, 98, 0.4) !important;
    transform: translateY(-3px) !important;
}

/* Add a special glow effect for the featured button */
.featured-button .button-glow {
    background: linear-gradient(120deg, 
        rgba(255, 255, 255, 0), 
        rgba(255, 215, 141, 0.1), 
        rgba(255, 255, 255, 0));
    background-size: 200% 100%;
}

/* Restore the subscribe button hover effect */
.subscribe-button:hover .button-glow {
    opacity: 1;
    animation: subtleShine 2s ease-in-out infinite;
}

.featured-button:hover .button-glow {
    opacity: 1;
    animation: featuredShine 2.5s ease-in-out infinite;
}

/* Create a special animation for the featured button */
@keyframes featuredShine {
    0% {
        background-position: 200% 0;
        opacity: 0.5;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        background-position: -200% 0;
        opacity: 0.5;
    }
}

/* Star positioning - simplified but effective */
@media (min-width: 768px) {
    .moons .star {
        opacity: 0.7;
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
    }
    
    .moons .star:nth-child(3n) {
        width: 2px;
        height: 2px;
        animation-duration: 3s;
    }
    
    .moons .star:nth-child(3n+1) {
        width: 1.5px;
        height: 1.5px;
        animation-duration: 4s;
    }
    
    .moons .star:nth-child(3n+2) {
        width: 1px;
        height: 1px;
        animation-duration: 5s;
    }
    
    .moons .star:nth-child(1) { top: 15%; left: 20%; animation-delay: 0.2s; }
    .moons .star:nth-child(2) { top: 25%; left: 40%; animation-delay: 0.5s; }
    .moons .star:nth-child(3) { top: 10%; left: 60%; animation-delay: 0.8s; }
    .moons .star:nth-child(4) { top: 20%; left: 80%; animation-delay: 1.1s; }
    .moons .star:nth-child(5) { top: 35%; left: 10%; animation-delay: 1.4s; }
    .moons .star:nth-child(6) { top: 45%; left: 30%; animation-delay: 1.7s; }
    .moons .star:nth-child(7) { top: 30%; left: 50%; animation-delay: 2s; }
    .moons .star:nth-child(8) { top: 40%; left: 70%; animation-delay: 2.3s; }
    .moons .star:nth-child(9) { top: 55%; left: 15%; animation-delay: 2.6s; }
    .moons .star:nth-child(10) { top: 65%; left: 35%; animation-delay: 2.9s; }
    .moons .star:nth-child(11) { top: 50%; left: 55%; animation-delay: 3.2s; }
    .moons .star:nth-child(12) { top: 60%; left: 75%; animation-delay: 3.5s; }
    .moons .star:nth-child(13) { top: 75%; left: 25%; animation-delay: 0.3s; }
    .moons .star:nth-child(14) { top: 85%; left: 45%; animation-delay: 0.6s; }
    .moons .star:nth-child(15) { top: 70%; left: 65%; animation-delay: 0.9s; }
    .moons .star:nth-child(16) { top: 80%; left: 85%; animation-delay: 1.2s; }
    .moons .star:nth-child(17) { top: 12%; left: 30%; animation-delay: 1.5s; }
    .moons .star:nth-child(18) { top: 22%; left: 50%; animation-delay: 1.8s; }
    .moons .star:nth-child(19) { top: 37%; left: 20%; animation-delay: 2.1s; }
    .moons .star:nth-child(20) { top: 47%; left: 40%; animation-delay: 2.4s; }
    .moons .star:nth-child(21) { top: 62%; left: 10%; animation-delay: 2.7s; }
    .moons .star:nth-child(22) { top: 72%; left: 30%; animation-delay: 3s; }
    .moons .star:nth-child(23) { top: 82%; left: 50%; animation-delay: 3.3s; }
    .moons .star:nth-child(24) { top: 17%; left: 75%; animation-delay: 3.6s; }
}

/* Animations */
@keyframes twinkle {
    0% { opacity: 0.2; transform: scale(0.8); }
    100% { opacity: 0.8; transform: scale(1.2); }
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 0.6; }
    100% { transform: scale(1.1); opacity: 0.8; }
}

@keyframes subtleShine {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

@keyframes buttonGlow {
    0% { opacity: 0.4; }
    100% { opacity: 0.7; }
}

/* Media queries */
@media (max-width: 768px) {
    .night-card.featured-card {
        transform: scale(1.02);
        margin: 20px 0;
    }
    
    .moons .star {
        opacity: 0.4;
    }
}
  
  
  