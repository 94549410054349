.navWrapper {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  max-width: 1366px;
  margin: 0 auto;
  height: 56px;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 24px;
}

.logo {
  height: 32px;
  width: auto;
}

.navLinks {
  display: flex;
  gap: 24px;
  align-items: center;
}

.navLinks a {
  color: #11311B;
  text-decoration: none;
  font-size: 14px;
  transition: opacity 0.2s;
}

.navLinks a:hover {
  opacity: 0.8;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.signInButton {
  padding: 8px 24px;
  font-weight: 600;
  background: #11311B;
  border: 1px solid #11311B;
  color: #C7AE48;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: opacity 0.2s;
}

.signInButton:hover {
  opacity: 0.9;
}

/* Mobile Menu Button */
.menuButton {
  display: none;
  background: none;
  border: none;
  color: #11311B;
  cursor: pointer;
  padding: 8px;
}

.menuButton svg {
  font-size: 24px;
  
}

/* Mobile Menu Dropdown */
.mobileMenu {
  display: none;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 24px 0;
  text-align: center;
  box-shadow: 0 4px 20px rgba(17, 49, 27, 0.1);
  border-top: 1px solid rgba(17, 49, 27, 0.08);
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mobileMenu a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #11311B;
  text-decoration: none;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  transition: background-color 0.2s ease;
}

.mobileMenu a:not(.mobileSignIn):hover {
  font-weight: 520;
}

.mobileMenu a:not(:last-child):after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  height: 1px;
  background: rgba(17, 49, 27, 0.08);
}

.mobileSignIn {
  background: #11311B;
  color: #C7AE48 !important;
  margin: 16px 24px 0;
  padding: 16px 24px !important;
  font-weight: 600 !important;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
  transition: opacity 0.2s ease !important;
}

.mobileSignIn:hover {
  opacity: 0.9;
}

/* Add icons to menu items */
.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navLinks {
    display: none;
  }

  .signInButton {
    display: none;
  }

  .menuButton {
    display: block;
  }

  .mobileMenu {
    display: block;
  }
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .nav {
    padding: 12px 24px;
  }
}

/* Mobile (393px) */
@media (max-width: 430px) {
  .nav {
    padding: 12px 16px;
  }

  .logo {
    width: 38px;
    height: 38px;
  }
} 