.heroSection {
  position: relative;
  width: 100%;
  height: 855px;
  background-image: url('../../images/hero-background.png');
  background-size: cover;
  background-position: center;
}

.heroBackground {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Sign In Button */
.signInButton {
  position: absolute;
  width: 123px;
  font-weight: 600;
  height: 46px;
  top: 24px;
  right: 24px;
  padding: 8px 24px;
  background-color: #C7AE48;
  color: #11311B;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  z-index: 10; /* Ensure button is clickable */
  text-align: center; /* Center the text */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 3D floating effect enhancements */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid rgba(199, 174, 72, 0.15);
  transform: translateZ(0);
  backface-visibility: hidden;
  overflow: hidden;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

/* Hover state enhancement */
.signInButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, 0.15);
  background-color: #C7AE48;
}

/* Add subtle highlight glow */
.signInButton::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20px;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(35deg);
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events: none;
}

/* Move the highlight on hover */
.signInButton:hover::after {
  left: 120%;
}

/* Specific enhancement for scrolling state (to be added in JS) */
.signInButton.scrolling {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3), 0 8px 15px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}

@supports (-webkit-touch-callout: none) {
  .signInButton {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 170px;
  top: 0;
  width: 413px;
}

/* Logo Section */
.logo {
  width: 128px;
  height: 132px;
  margin-top: 176.09px;
}

.brandName {
  margin-top: 8px;
  color: #11311B;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}

/* Main Content */
.mainContent {
  position: absolute;
  top: 380px;
  left: 128px;
  max-width: 500px;
}

.title {
  margin-top: 25px;
  margin-bottom: 5px;
  width: 413px;
  letter-spacing: 0px;
  height: 137px;
  font-family: "Roboto";
  font-size: 62px;
  line-height: 1;
  text-align: center;
  color: #11311B;
  text-transform: capitalize;
}

.description {
  margin-top: 0px;
  font-size: 16px;
  line-height: 1.6;
  color: #11311B;
  width: 400px;
  height: 76px;
  text-align: center;
}

.learnMoreButton {
  margin-top: 55px;
  width: 166px;
  font-weight: 600;
  height: 50px;
  padding: 12px 32px;
  background-color: #11311B;
  color: #C7AE48;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  z-index: 10; /* Ensure button is clickable */
  /* 3D floating effect enhancements */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid rgba(199, 174, 72, 0.15);
  transform: translateZ(0);
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
}

/* Hover state enhancement */
.learnMoreButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, 0.15);
  background-color: #18422A;
}

/* Add subtle highlight glow */
.learnMoreButton::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20px;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(35deg);
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events: none;
}

/* Move the highlight on hover */
.learnMoreButton:hover::after {
  left: 120%;
}

/* Tablet (1024px) */
@media (max-width: 1500px) {
  .heroSection {
    height: auto;

  }

  .content {
    position: static;
    transform: none;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Optional background for readability */
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
}

/* Mobile (430px) */
@media (max-width: 430px) {
  .mobileNav {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: #fff;
  }

  .heroSection {
    height: auto;
    margin-top: 56px;
    background: none;
  }

  .content {
    position: static;
    transform: none;
    width: 100%;
    padding: 20px 24px;
    text-align: center;
    background-color: #DDDDDD;
  }

  .logo {
    width: 80px;
    height: auto;
    margin: 0 auto 16px;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .title {
    font-size: 32px;
    color: #11311B;
    margin-bottom: 10px;
    line-height: 1.2;
    height: auto;
    width: 100%;
  }

  .description {
    font-size: 16px;
    line-height: 1.4;
    color: #11311B;
    margin-bottom: 0px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 100%;
  }

  .learnMoreButton {
    padding: 10px 24px;
    font-size: 14px;
    width: auto;
    margin-bottom: 0px;
  }

  /* Image Section */
  .heroBackground {
    width: 100%;
    height: 500px;
    background-image: url('../../images/MobileHome.png');
    background-position: center;
    background-size: cover;
    position: static;
  }

  .signInButton {
    display: none; /* Completely hide the sign in button on mobile */
  }
}

.button {
  background-color: #11311B;
  color: #C7AE48;
  /* ... rest of button styles ... */
}

.mobileNav {
  display: none; /* Hidden by default on desktop */
}