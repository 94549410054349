.bannerContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #E2E2E2;
  margin: 0 -50vw;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
}

.banner {
  position: relative;
  width: 100%;
  height: 144.97px;
  overflow: hidden;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.07);
}

.bannerTrack {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(17,49,27,0.02) 100%);
}

.bannerContent {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  will-change: transform;
  animation: scroll 25s linear infinite;
}

.textGroup {
  display: flex;
  align-items: center;
  transform-style: preserve-3d;
  perspective: 1000px;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.textGroup:hover {
  transform: scale(1.05) translateZ(20px);
}

.text {
  font-family: 'Playfair Display', Georgia, serif;
  font-weight: 100;
  font-size: 43px;
  letter-spacing: -0.02em;
  color: #11311B;
  text-transform: capitalize;
  padding: 0 60px;
  position: relative;
  transition: all 0.4s ease;
}

.text:hover {
  color: #265c37;
  text-shadow: 0 5px 15px rgba(17, 49, 27, 0.15);
}

.text::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, rgba(17, 49, 27, 0), rgba(17, 49, 27, 0.5), rgba(17, 49, 27, 0));
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  transform-origin: center;
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.textGroup:hover .text::before {
  transform: translateX(-50%) scaleX(1);
}

.dot {
  font-size: 34px;
  color: #11311B;
  opacity: 0.5;
  transform: rotate(0deg);
  display: inline-block;
  position: relative;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.textGroup:hover .dot {
  transform: rotate(30deg) scale(1.3);
  opacity: 0.8;
}

.decorative {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(17, 49, 27, 0.05) 0%, rgba(17, 49, 27, 0) 70%);
  will-change: transform;
  z-index: 1;
  transition: transform 0.1s ease-out;
}

.decorative:nth-child(1) {
  width: 300px;
  height: 300px;
  top: -100px;
  left: 10%;
  filter: blur(20px);
}

.decorative:nth-child(2) {
  width: 200px;
  height: 200px;
  bottom: -50px;
  right: 20%;
  filter: blur(15px);
  background: radial-gradient(circle, rgba(17, 49, 27, 0.03) 0%, rgba(17, 49, 27, 0) 70%);
}

.decorative:nth-child(3) {
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(10px);
  background: radial-gradient(circle, rgba(17, 49, 27, 0.04) 0%, rgba(17, 49, 27, 0) 70%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, 
    rgba(226, 226, 226, 1) 0%, 
    rgba(226, 226, 226, 0) 10%, 
    rgba(226, 226, 226, 0) 90%, 
    rgba(226, 226, 226, 1) 100%
  );
  pointer-events: none;
  z-index: 3;
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

/* Tablet (1024px) */
@media (max-width: 1024px) {
  .banner {
    height: 140px;
  }
  
  .text {
    font-size: 36px;
    padding: 0 40px;
  }
  
  .dot {
    font-size: 28px;
  }
}

/* Mobile (393px) */
@media (max-width: 600px) {
  .banner {
    height: 100px;
  }
  
  .text {
    font-size: 24px;
    padding: 0 25px;
  }
  
  .dot {
    font-size: 20px;
  }
  
  .decorative {
    display: none;
  }
} 