.heroSection {
  position: relative;
  width: 100%;
  height: 855px;
  background-image: url('../../images/About.png');
  background-size: cover;
  background-position: center;
  margin-top: 34px; /* Height of nav + padding */
}

.heroBackground {
  width: 100%;
  height: 100%;
  position: relative;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1366px;
  margin: 0 auto;
}

.logo {
  width: 48px;
  height: 48px;
}

.navLinks {
  display: flex;
  gap: 32px;
}

.navLinks a {
  color: #11311B;
  text-decoration: none;
  font-size: 16px;
}

.signInButton {
  width: 123px;
  height: 46px;
  
  padding: 8px 24px;
  background-color: #11311B;
  color: #C7AE48;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  /* 3D floating effect enhancements */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid rgba(199, 174, 72, 0.15);
  transform: translateZ(0);
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
}

/* Hover state enhancement */
.signInButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, 0.15);
  background-color: #18422A;
}

/* Add subtle highlight glow */
.signInButton::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20px;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(35deg);
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events: none;
}

/* Move the highlight on hover */
.signInButton:hover::after {
  left: 120%;
}

.content {
  position: absolute;
  right: 170px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 413px;
  text-align: center;
}

.titleContainer {
  text-align: center;
  margin-bottom: 24px;
}

.title {
  font-size: 48px;
  font-family: "Roboto";
  color: #11311B;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  padding-bottom: 12px;
}

.titleDivider {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background: #C7AE48;
  transition: width 0.3s ease;
  display: block;
}

.title:hover .titleDivider {
  width: 80px;
}

.description {
  font-size: 16px;
  line-height: 1.6;
  color: #11311B;
  margin-bottom: 32px;
  
}

.getStartedButton {
  padding: 12px 32px;
  font-weight: 600;
  background-color: #11311B;
  color: #C7AE48;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  /* 3D floating effect enhancements */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid rgba(199, 174, 72, 0.15);
  transform: translateZ(0);
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;
}

/* Hover state enhancement */
.getStartedButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.25), 0 6px 10px rgba(0, 0, 0, 0.15);
  background-color: #18422A;
}

/* Add subtle highlight glow */
.getStartedButton::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20px;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(35deg);
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  pointer-events: none;
}

/* Move the highlight on hover */
.getStartedButton:hover::after {
  left: 120%;
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .getStartedButton {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
}

/* Tablet only (between 431px and 1500px) */
@media (min-width: 431px) and (max-width: 1500px) {
  .heroSection {
    height: 855px;
  }

  .heroBackground {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .content {
    position: static;
    transform: none;
    padding: auto;
    max-width: 413px;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
  }
}

/* Mobile (430px and below) */
@media (max-width: 430px) {
  .heroSection {
    height: auto;
    margin-top: 56px;
    background: none;
    display: flex;
    flex-direction: column;
  }

  .heroBackground {
    width: 100%;
    height: auto;
    background-image: url('../../images/About.png');
    background-size: cover;
    background-position: center;
  }

  .content {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    max-width: 100%;
    width: 100%;
    padding: 20px 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .title {
    font-size: 28px;
    margin-top: 0;
    padding-bottom: 12px;
  }

  .titleDivider {
    bottom: -4px;
    width: 50px;
  }

  .title:hover .titleDivider {
    width: 70px;
  }

  .description {
    font-size: 16px;
    margin-bottom: 24px;
  }

  /* Add button at bottom of About Us section */
  .content .getStartedButton {
    display: block;
    margin: 0px auto 0;
    border-radius: 8px;
    position: relative;
    bottom: 0;
    width: auto;
  }
}

.mobileHeroImage {
  display: none; /* Hidden by default */
}

@media (max-width: 430px) {
  .mobileHeroImage {
    display: block;
    width: 100%;
    height: 500px;
    background-image: url('../../images/AboutMobile.png');
    background-size: cover;
    background-position: center 20%;
  }
} 