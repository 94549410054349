/* Reset for public pages */
.public-page {
  position: relative !important;
  width: 100% !important;
  height: auto !important;
  overflow: auto !important;
  background-color: #f5f5f5;
}

.public-page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.public-page-content {
  flex: 1 0 auto;
  width: 100%;
  padding-bottom: 60px;
}

/* Remove duplicate styles */
.public-container,
.public-content {
  display: none;
}

/* Update Footer styles */
.public-page .MuiContainer-root {
  padding-bottom: 0;
  min-height: auto;
}

/* Override the fixed position from index.css for public pages */
.public-page html,
.public-page body {
  position: relative !important;
  overflow: auto !important;
  height: auto !important;
}

/* Public page container */
.public-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Main content area */
.public-content {
  flex: 1 0 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Public page scrollbar styling */
.public-content::-webkit-scrollbar {
  width: 0.4em;
}

.public-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Footer for public pages */
.public-footer {
  flex-shrink: 0;
}