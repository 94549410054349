.termsPage {
  min-height: 100vh;
  background-color: #fff;
}

.mainContent {
  padding-top: 56px; /* Height of nav */
}

.heroSection {
  background-color: #11311B;
  background-image: url('../../images/Texture.png');
  background-size: cover;
  background-position: center;
  padding: 60px 24px;
  text-align: center;
  color: #C7AE48;
}

.heroSection h1 {
  font-size: 48px;
  margin-bottom: 16px;
}

.contentSection {
  max-width: 800px;
  margin: 0 auto;
  padding: 48px 24px;
}

.section {
  margin-bottom: 48px;
}

.section h2 {
  color: #11311B;
  font-size: 24px;
  margin-bottom: 24px;
}

.section p {
  color: #333;
  line-height: 1.6;
  margin-bottom: 16px;
}

.section ul {
  list-style-type: none;
  padding-left: 0;
}

.section li {
  color: #333;
  padding: 8px 0;
  padding-left: 24px;
  position: relative;
}

.section li::before {
  content: "•";
  color: #C7AE48;
  position: absolute;
  left: 0;
}

.link {
  color: #11311B;
  text-decoration: underline;
  transition: opacity 0.2s;
}

.link:hover {
  opacity: 0.8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .heroSection h1 {
    font-size: 36px;
  }

  .section h2 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .heroSection {
    padding: 40px 16px;
  }

  .heroSection h1 {
    font-size: 28px;
  }

  .contentSection {
    padding: 32px 16px;
  }
} 